// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  baseUrl: 'https://aodocs-gmail-export-addon-dev.appspot.com/',
  apiUrl: 'api/',
  aodocsUrl: 'https://ao-docs-dev.appspot.com/',
  clientId: '740220827408-tqm7unfii9mb4e6kk9cbh67pd3ajjar6',
  version: 'develop',
  firebaseApiKey: 'AIzaSyBErauZagyiQpiu9K41b1M9ze6B_s9e-Y8',
  firebaseAuthDomain: 'ao-docs-dev.firebaseapp.com',
  firebaseRefreshUrl: 'https://securetoken.googleapis.com/v1/token',
  msClientId: '71c9f878-ca4c-4fdf-a867-62bc1b87aac0',
  msLoginURL: 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize',
  msRedirectURI: 'https://aodocs-gmail-export-addon-dev.appspot.com/auth',
  msScopes: 'openid%20offline_access%20https%3A%2F%2Fgraph.microsoft.com%2Fmail.read%20https%3A%2F%2Fgraph.microsoft.com%2Fmail.readwrite',
  production: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
